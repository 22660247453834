
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";
import Generators from "@/views/reusable/buy-insurance/house-insurance/generators.json";

export default defineComponent({
  name: "generator-component",
  components: { Field, ErrorMessage },
  props: {
    valueClasses: String,
    brandClasses: String,
    photoClasses: String,
    photoReceiptClasses: String,
    rate: Number,
    publicId: String,
    underwriterId: String,
    coopPublicId: String,
    umbrellaPublicId: String,
  },
  data() {
    return {
      generatorLists: Generators,
      generators: [
        {
          generatorValue: "",
          generatorBrand: "",
          generatorPhoto: "",
          generatorReceiptPhoto: "",
        },
      ],
      totalPremium: 0.0 as number,
    };
  },
  methods: {
    premium(event, fieldName) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      // const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false && event.target.value.length > 6) {
        // Calculate premium from vehicle value
        const payload = {
          businessClass: "HOM",
          sumInsured: 0,
        } as object;

        if (this.underwriterId) {
          payload["underwriter"] = this.underwriterId;
          payload["package"] = this.publicId;
        } else {
          payload["cooperative"] = this.coopPublicId;
          payload["policy"] = this.umbrellaPublicId;
        }

        payload[fieldName] = value;

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(variables.PREMIUM_ROUTE, payload)
            .then(({ data }) => {
              variables.premiumCalculator(
                event.target.ariaValueNow,
                data.data.grossPremium,
                event.target.ariaValueText
              );

              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }
    },
    addDynamicField() {
      this.generators.push({
        generatorValue: "",
        generatorBrand: "",
        generatorPhoto: "",
        generatorReceiptPhoto: "",
      });
    },
    removeDynamicField(counter) {
      // Remove dynamic field
      // this.generators.splice(counter, 1);
      $(".remove-" + counter).remove();

      setTimeout(() => {
        // Update total premium
        this.totalPremium = variables.totalPremiumValue();
      }, 200);
    },
    imagesSelected(event, position) {
      this.generators[position].generatorPhoto = event.target.files;
    },
    receiptsSelected(event, position) {
      this.generators[position].generatorReceiptPhoto = event.target.files;
    },
  },
  computed: {
    mainRate() {
      return this.rate;
    },
  },
});
