
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import OwnBuilding from "@/views/cooperative-admin/insurance-packages/house-insurance/building/partials/OwnBuilding.vue";
import RentBuilding from "@/views/cooperative-admin/insurance-packages/house-insurance/building/partials/RentBuilding.vue";
import TotalPremiumWidget from "@/components/packages/TotalPremiumWidget.vue";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";

export default defineComponent({
  name: "building-house-insurance",
  components: {
    OwnBuilding,
    RentBuilding,
    TotalPremiumWidget,
  },
  data() {
    return {
      publicId: this.$route.params.publicId,
      coopPublicId: this.$route.params.coopPublicId,
      packageName: this.$route.params.package,
      buildingChoice: false,
      buildingChoice2: false,
      result: {
        householderPublicId: "",
        commercialCartItemPublicId: "",
        hasHouseholderCartItem: true || (false as boolean),
        hasCommercialCartItem: true || (false as boolean),
      },
      alternativeAccommodationValueRate: 0.001,
      buildingReinstatementValueRate: 0.001,
      lossOfRentValueRate: 0.001,
      personalEffectsRate: 0.045,
      cashValueRate: 0.006,
      thirdPartLiabilityValueRate: 0.00075,
      generatorRate: 0.0025,
      members: [],
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Building", [
        "Cooperative",
        "Insurance Packages",
        "House Insurance",
      ]);
    });
  },
  created() {
    this.getMembers();

    //Set page title
    document.title =
      "Cooperative Building Householders Insurance  | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    getMembers() {
      // Get the private motor rate
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.COOPERATIVE_MEMBERS_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.members = data.data.sort(variables.dynamicSort("name"));
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
