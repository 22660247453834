
import { defineComponent, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import State from "@/components/reusable/State.vue";
import Address from "@/components/reusable/Address.vue";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import ContentsBuildingModal from "@/components/modals/ContentsBuildingModal.vue";
import ScheduleInfoModal from "@/components/modals/ScheduleInfoModal.vue";
import Generator from "@/components/reusable/Generator.vue";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import BuildingTypes from "@/views/reusable/buy-insurance/house-insurance/building-types.json";

export default defineComponent({
  name: "cooperative-rented-building-house-insurance-create-component",
  components: {
    Form,
    Field,
    ErrorMessage,
    State,
    Address,
    ImagesUploader,
    ContentsBuildingModal,
    ScheduleInfoModal,
    Generator,
  },
  props: {
    alternativeAccommodationValueRate: Number,
    personalEffectsRate: Number,
    cashValueRate: Number,
    thirdPartLiabilityValueRate: Number,
    generatorRate: Number,
    publicId: String,
    members: Array,
    coopPublicId: String,
  },
  data() {
    return {
      buildingTypes: BuildingTypes,
      alternativeAccommodation: "",
      totalPremium: 0.0 as number,
      totalSumInsured: 0.0 as number,
      buildingType: "",
      state: "",
      localGovernmentArea: "",
      ward: "",
      zipCode: "",
      streetAddress: "",
      buildingReinstatementValue: "",
      lossOfRentValue: "",
      imagesOfBuilding: "",
      houseHoldGoodsAndPersonalEffectsValue: "",
      cashValue: "",
      thirdPartyLiabilityLimit: "",
      schedule: "",
      insurancePackage: "",
      mutatedBusinessType: "BUI",
      mutatedBusinessClass: "HOM",
      mutatedPublicId: this.publicId,
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const metadataValidator = Yup.object().shape({
      member: Yup.mixed()
        .required()
        .label("Member"),
      buildingType: Yup.mixed()
        .required()
        .label("Type of building"),
      state: Yup.string()
        .required()
        .label("State"),
      localGovernmentArea: Yup.string()
        .required()
        .label("L.G.A"),
      ward: Yup.string()
        .required()
        .label("Town/City"),
      streetAddress: Yup.string()
        .required()
        .label("Street address"),
      alternativeAccommodation: Yup.string()
        .required()
        .label("Alternative accommodation value"),
      imagesOfBuilding: Yup.mixed()
        .required()
        .label("Photo of building"),
      houseHoldGoodsAndPersonalEffectsValue: Yup.string()
        .required()
        // .min(10000)
        .label("Contents value"),
      cashValue: Yup.string()
        .notRequired()
        .max(10000)
        .label("Cash value"),
      thirdPartyLiabilityLimit: Yup.string()
        .notRequired()
        // .min(10000)
        .label("Third-party liability value"),
      generators: Yup.array().of(
        Yup.object().shape({
          generatorValue: Yup.mixed()
            .notRequired()
            .label("Generator value"),
          generatorBrand: Yup.mixed()
            .notRequired()
            .label("Generator brand"),
          generatorPhoto: Yup.mixed()
            .notRequired()
            .label("Photo of generator"),
          generatorReceiptPhoto: Yup.mixed()
            .notRequired()
            .label("Photo of generator receipt"),
        })
      ),
    });

    const saveRentedBuilding = (values, { resetForm }) => {
      console.clear();
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send form data
          if (JwtService.getToken()) {
            const form = document.forms[0];
            const formData = new FormData(form);

            formData.append("totalPremium", values["totalPremium"]);
            formData.append("businessType", values["businessType"]);
            formData.append("businessClass", values["businessClass"]);
            formData.append("umbrellaPolicyID", values["umbrellaPolicyID"]);
            formData.append("package", "BUI");
            formData.append("packageType", "TEN");
            // return new Response(formData).text().then(console.log);

            ApiService.setHeader();
            ApiService.post(variables.COOPERATIVE_CART_ROUTE, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                resetForm();
                variables.toastAlert(response.data.data.message, "success");
                router.push({
                  name: "cooperative-admin-insurance-packages-cart",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    return {
      submitButton1,
      saveRentedBuilding,
      // metadataValidator,
    };
  },
  created() {
    //Set page title
    document.title =
      "Cooperative: Rented Building House Insurance | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    premium(event, fieldName) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));

      // Remove comma from vehicle value
      const value = variables.removeComma(newValue);
      // const value = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false && event.target.value.length > 6) {
        // Calculate premium from vehicle value
        const payload = {
          businessClass: "HOM",
          cooperative: this.coopPublicId,
          policy: this.publicId,
          sumInsured: 0,
        } as object;

        payload[fieldName] = value;

        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.post(variables.PREMIUM_ROUTE, payload)
            .then(({ data }) => {
              variables.premiumCalculator(
                event.target.ariaValueNow,
                data.data.grossPremium,
                event.target.ariaValueText
              );

              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }
    },
    acceptNumberOnly(event) {
      variables.acceptNumberOnly(event);
    },
  },
});
